import React from "react";
import { Box, Typography } from "@mui/material";

export interface PortfolioCardValue {
  title: string;
  description: string;
  backgroundImg: string;
  onClick: any;
}

const PortfolioCard = (props: PortfolioCardValue) => {
  return (
    <div>
      <Box onClick={props.onClick} style={{cursor: "pointer"}}>
        <img src={props.backgroundImg}
          width="100%"
          height="100%"
          loading="lazy"
          title="developer portfolio"
          alt="developer portfolio" />
        <Typography
          variant="h2"
          sx={{
            marginTop: "1rem",
            fontSize: {
              xl: "1.5rem",
              lg: "1.5rem",
              md: "1.5rem",
              sm: "2rem",
              xs: "2rem",
            },
          }}
        >
          {props.title}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontFamily: "Noto Serif",
            fontSize: {
              xl: ".9rem",
              lg: ".9rem",
              md: "0.9rem",
              sm: "1.25rem",
              xs: "1.25rem",
            },
            color: "#000",
          }}
        >
          {props.description}
        </Typography>
      </Box>
    </div>
  );
};

export default PortfolioCard;
