import AboutUsSection from "../../molecule/AboutUsSection/AboutUsSection";
import ContactForm from "../../molecule/ContactForm/ContactForm";
import Footer from "../../molecule/Footer/Footer";
import HeroArea from "../../molecule/HeroArea/HeroArea";
import OurPartners from "../../molecule/OurPartners/OurPartners";
import PortfolioSection from "../../molecule/PortfolioSection/PortfolioSection";
import Service from "../../molecule/Service/Service";
import { Box } from "@mui/material";



const Home = () => {

  const styleComp = (
    <div style={{ color: "#212121", fontFamily: "Noto Serif" }}>
      Tell us your problem and get the
      <span
        style={{
          color: "#6D30B9",
          fontFamily: "Noto Serif",
          fontWeight: "bold",
        }}
      >
        {" "}
        best services for you
      </span>{" "}
    </div>
  );

  return (
    <div>
      <HeroArea maxWidth="false" />
      <AboutUsSection />
      <PortfolioSection />
      <Service maxWidth="false" />
      {/* <PortfolioSection /> */}
      <OurPartners />
      <Box sx={{ backgroundColor: "#f4f4f4" }}>
        <ContactForm
          title="Interested in working with us?"
          description={styleComp}
        />
      </Box>
      <Footer />
    </div>
  );
};

export default Home;
